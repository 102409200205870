html,
body {
  height: 100%;
  text-align: center;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
