.phillip-and-vivian-content {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  font-family: Helvetica, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
  color: black;
  /*font-size: 3.2vw;  2vw 1.65rem */
  font-size: 1rem;

  div:only-child {
    margin-bottom: 30%;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.25rem;
    div:only-child {
      margin-bottom: 0;
    }
  }
}

.phillip-and-vivian-content div {
  position: relative;
}

.phillip-and-vivian-content h2 {
  position: relative;
  left: -10%;
  font-size: 16vw;
  transform: rotate(-12deg);
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: normal;
  margin-bottom: 35px;

  @media screen and (min-width: 768px) {
    font-size: 11vw;
    /* margin-bottom: 0px; */
    left: -30%;
  }

  @media screen and (min-width: 1025px) {
    font-size: 9vw;
    margin-bottom: 0px;
    left: -30%;
  }
}

.phillip-and-vivian-content h1 {
  font-family: Athelas, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  /*font-size: 6vw;  6vw 4.5rem*/
  font-size: 2.5rem;
  font-weight: lighter;
  letter-spacing: 5px;
  margin: 30px 0;
  padding: 15px 0;

  display: grid;
  grid-template-columns: repeat(1, auto);
  gap: 0;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, auto);
    gap: 1rem;
    font-size: 3.7rem;
  }
}

.phillip-and-vivian-content p {
  text-transform: uppercase;
  font-weight: lighter;
  letter-spacing: 2px;
}

.phillip-and-vivian-content button,
:global(#submit) {
  font-family: Helvetica, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
  width: 25%;
  border: none;
  cursor: pointer;
  padding: 1%;

  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }
}

:global(#submit) {
  width: 30%;
  font-weight: lighter;

  @media screen and (min-width: 768px) {
    width: 20%;
    font-weight: normal;
  }
}

p.date {
  font-weight: normal;
}

.deadline {
  font-weight: normal;
  color: #c89d57;
  padding-top: 8px;

  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }
}

.phillip-and-vivian form h2 {
  font-family: BrittanySignature, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  font-weight: normal;

  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
  }
}

/* Form styling */

.phillip-and-vivian {
  form p {
    font-family: Helvetica, Helvetica Neue LT Std, Avenir light, Arial, sans-serif;
    text-transform: uppercase;
    color: black;
    font-size: 2.25vw;
    font-size: 0.75rem;
    font-weight: lighter;
    letter-spacing: 2px;

    @media screen and (min-width: 768px) {
      font-size: 1.75vw;
    }

    @media screen and (min-width: 1024px) {
      font-size: 1.25vw;
    }
  }

  textarea,
  input,
  select {
    font-family: Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
    font-weight: lighter;
  }

  label {
    font-weight: normal;
  }
}

/* Form Submmitted styling */

.phillip-and-vivian {
  section {
    max-width: 90%;
    h2 {
      position: relative;
      left: -65%;
      font-size: 7vw;
      transform: rotate(-12deg);
      margin-bottom: 60px;
      margin-top: 20px;
      font-weight: normal;
      font-family: BrittanySignature, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
    }

    h3 {
      font-family: Athelas, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      font-size: 6vw;
      font-weight: lighter;
      letter-spacing: 5px;
      margin: 30px 0;
      padding: 15px 0;

      @media screen and (min-width: 1025px) {
        font-size: 3vw;
      }
    }

    p {
      font-weight: lighter;
      letter-spacing: 2px;
    }

    p:first-of-type {
      font-family: Athelas, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      color: black;
      font-size: 1.25vw;
      font-weight: normal;
      letter-spacing: 3px;

      @media screen and (max-width: 768px) {
        font-size: 2vw;
      }
    }

    :global(.sorry) h2 {
      left: -25%;
      white-space: nowrap;
      transform: rotate(-8deg);
      margin-bottom: 40px;

      @media screen and (min-width: 768px) {
        margin-bottom: 60px;
      }
    }

    :global(.thank-you) h2 {
      left: -30%;
      font-size: 11vw;

      @media screen and (min-width: 768px) {
        font-size: 7vw;
      }

      @media screen and (min-width: 1200px) {
        margin-bottom: 100px;
      }
    }
  }

  section > div {
    font-family: Helvetica, Helvetica Neue LT Std, Avenir light, Arial, sans-serif;
    color: black;
    font-size: 3.2vw;

    @media screen and (min-width: 1025px) {
      font-size: 1.25rem;
    }
  }
}
