// Combined with animation property below to animate condition form components
@keyframes transformY {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.form-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    align-self: flex-start;
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 85%;

  @media screen and (min-width: 768px) {
    width: 60%;
  }

  select {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
        no-repeat 99% 50%,
      white;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    /* and then whatever styles you want*/
    /* height: 30px; */
    /* width: 100px; */
    /* padding: 5px; */
  }

  input,
  select,
  textarea {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.4rem;
    resize: none;
    border: none;

    :global(#firstName),
    :global(#surnameName) {
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
      padding: 0.75rem;
      margin-bottom: 1rem;
    }
  }

  :global(#message) {
    margin-bottom: 1rem;
  }

  label {
    white-space: nowrap;
    font-size: 0.75rem;

    @media screen and (min-width: 768px) {
      font-size: 1rem;
    }
  }

  .translate {
    animation: transformY 0.5s ease-in;
  }

  div {
    display: flex;
    align-items: center;

    &.name-div {
      input:first-of-type {
        margin-right: 10px;
      }

      input {
        flex: 1 1 50%;
        max-width: 50%;
        width: 50%;
      }

      @media screen and (min-width: 768px) {
        height: 48px;
      }
    }

    &.radio-div {
      height: 48px;
      justify-content: space-evenly;
      font-size: 0.75rem;

      label:first-child {
        border-right: none;
      }

      @media screen and (min-width: 768px) {
        margin-bottom: 1rem;
        font-size: inherit;

        label:first-child {
          border-right: 1px solid black;
        }
      }
    }

    &.contact-div {
      height: 48px;

      :global(.PhoneInputInput) {
        margin-right: 0 !important;
      }

      input:first-of-type {
        margin-bottom: 0;
        margin-right: 10px;
        width: 50%;
      }

      input {
        flex: 1 1 50%;
      }

      @media screen and (min-width: 768px) {
        /* margin-bottom: 0.5rem; */

        :global(.PhoneInputCountry) {
          margin-bottom: 1rem; /* remove to even spacing email/phone and lead guest dietary requirements */
        }

        input:first-of-type {
          margin-bottom: 1rem;
        }
      }
    }

    &.additional-guest-div {
      justify-content: space-between;
      margin-bottom: 0.5rem;

      input[type="text"] {
        flex: 1 1 50%;
        margin-bottom: 0;
        max-width: 45%;
      }

      @media screen and (min-width: 768px) {
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    min-width: 275px;

    input,
    select,
    textarea,
    label {
      white-space: nowrap;
    }
  }
}

/*
 * Style the label so it looks like however you want.
 * Here's a quick rundown of how I did it here:

 * Some basic positioning styles, and we give it the pointer cursor to show 
 * that it's clickable
 */

form div label {
  flex-basis: 50%;
  padding: 0;
  cursor: pointer;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    padding: 5px 10px;
    align-items: center;
  }
}

/*
 * With how I decided to build this, the position: relative is super important.
 * We're going to position a pseudo element within this element(As it is the containing box)
 */

form div label span {
  position: relative;
  line-height: 22px;
}

/* 
 * Because we're using pseudo elements, a content property is required to make them appear.
 */

form div label span:before,
form div label span:after {
  content: "";
}

/*
 * We are using the :before peudo element as the actual button,
 * then we'll position the :after over it. You could also use a background-image,
 * font-icon, or really anything if you want different styles.
 * For the specific style we're going for, this approach is simply the easiest, but
 * once you understand the concept you can really do it however you like.
 */

form div label span:before {
  border: 1px solid #c89d57;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  right: -35px;
}

form div label span:after {
  background: #c89d57;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 3px;
  /*   left: 4px; */
  right: -21px;
  transition: 300ms;
  opacity: 0;
}

/*
 * This is the most important part of this whole file, if you understand what's happening here
 * you can really make this in so many different ways.
 * 
 * We start by selecting the input inside of the label, with "label input". From there we use the 
 * ":checked" selector to *only* select the input when it is checked. We then use the immediate sibling 
 * selector(+) to select the span, and then it's pseudo element :after(What we are using to mark the button)
 * Because we already styled the :after, all we have to do is set the opacity to 1, making it fade in.
 */

form div label input:checked + span:after {
  opacity: 1;
}

/* 
 * Hide the radio & checkbox inputs. 
 */
form div input[type="radio"] {
  /* display: none; */
  visibility: hidden;
}

form div input[type="checkbox"] {
  visibility: hidden;
}
