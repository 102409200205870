/* Designed for Phillip & Vivian */
$primary: #fff2dd;
$secondary: #c89d57;
$secondaryHover: #cfa766;

.cream-on-gold-container {
  background-color: $primary;
  color: $secondary;

  h2 {
    color: $secondary;
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: none !important;
    border: 1.5px solid $secondary;
  }

  button {
    background-color: $secondary;
    color: white;
    text-transform: uppercase;

    &:hover {
      background-color: $secondaryHover;
    }
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $secondary;
    opacity: 1; /* Firefox */
    /* text-transform: uppercase; */
  }
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $secondary;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $secondary;
}

select,
label {
  color: $secondary;
}
