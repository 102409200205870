/* All fonts imported in public/styles.css */

.trochut-font {
  font-family: Trochut, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
}

.jimmy-script-font {
  font-family: JimmyScript, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
}

.telegraf1-font {
  font-family: Telegraf1, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
}

.telegraf2-font {
  font-family: Telegraf2, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
}

.brittany-signature-font {
  font-family: BrittanySignature, Helvetica Neue LT Std, Avenir light, Helvetica, Arial, sans-serif;
}
